import React from 'react';

function ArrowRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M6.68251 15.5025C6.54001 15.5025 6.39751 15.45 6.28501 15.3375C6.06751 15.12 6.06751 14.76 6.28501 14.5425L11.175 9.65251C11.535 9.29251 11.535 8.70751 11.175 8.34751L6.28501 3.45751C6.06751 3.24001 6.06751 2.88001 6.28501 2.66251C6.50251 2.44501 6.86251 2.44501 7.08001 2.66251L11.97 7.55251C12.3525 7.93501 12.57 8.45252 12.57 9.00002C12.57 9.54751 12.36 10.065 11.97 10.4475L7.08001 15.3375C6.96751 15.4425 6.82501 15.5025 6.68251 15.5025Z" />
    </svg>
  );
}

export default ArrowRight;
