import React, { ComponentProps } from 'react';
import { useLocale } from 'next-intl';
import { usePathname, useSearchParams } from 'next/navigation';

import Link from 'next-intl/link';
import { Wrapper } from './styled';

type NavLinkProps = React.PropsWithChildren<ComponentProps<typeof Link>> & {
  activeClassName?: string;
  exact?: boolean;
  as?: any;
};

export function NavLink({ children, exact, ...props }: NavLinkProps) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const size = searchParams.get('size');
  const locale = useLocale();

  const asPath = `${pathname}${Number(size) > 0 ? '?' : ''}${searchParams.toString()}`;
  const localePrefix = `${locale === 'en' ? '' : `/${locale}`}`;
  const exactHref = props.href === '/' && localePrefix.length > 0 ? '' : props.href;
  const isActive = exact ? pathname === `${localePrefix}${exactHref}` || asPath === props.as : asPath.includes(props.href.toString());

  return (
    <Wrapper $isActive={isActive} {...props}>
      {children}
    </Wrapper>
  );
}
