import styled from 'styled-components';
import LocaleLink from '../LocaleLink';

export const Wrapper = styled(LocaleLink)`
  text-decoration: none;
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    img {
      width: 120px;
      height: 33px;
    }
  }
`;
