const themes = {
  light: {
    name: 'light',
    primary: '#2253F5',
    primaryText: '#101010',
    secondaryText: '#6F6F6F',
    borderColor: 'rgba(0, 0, 0, 0.1)',
    bg: '#FFF',
    searchInputBorderColor: 'rgba(128, 141, 158, 0.25)',
    htmlBg: '#ffffff',
    benefitsBg: '#F5F5F5',
    cardsBg: '#ffffff',
    navbarBg: '#ffffff',
    tocBorderColor: 'rgba(128, 141, 158, 0.1)',
    cardCategoryStripBg: 'rgba(128, 141, 158, 0.15)',
    cardBaseBg: 'rgba(128, 141, 158, 0.05)',
    downloadAppBg: 'rgba(233, 236, 242, 0.5)',
    notificationsBg: 'linear-gradient(180deg, #F8F8F9 43.57%, rgba(0, 0, 0, 0) 100%)',
    secondaryBtnBg: 'transparent',
    btnSecondaryActiveBg: '#D9D9D9',
    secondaryBtnBorder: '#f1f1f1',
    cardsBorder: 'rgba(0, 0, 0, 0.05)',
    translucentCardsBg: '#F5F5F5',
    panelSectionBg: 'linear-gradient(180deg, #FFFFFF 84.66%, rgba(255, 255, 255, 0) 100%)',
    howitworksSecondaryBg: 'rgba(231,231,231,0.2)',
    howitworksSmsBg: '#F0F0F0',
    svgDisabledColor: '#E9ECF2',
    tableBottomBlur: 'linear-gradient(rgba(255, 255, 255, 0) 0%,#ffffff 100%)',
    tableItemHoverBg: '#E0EAFD',
    specialDealsBg: 'linear-gradient(#FCFCFD 0%, #FCFCFD 200px, rgba(0, 0, 0, 0.05) 200px, rgba(0, 0, 0, 0.05) 100%)',
    howToGetNumberBg: '#F8F8F9',
    checkboxBorderColor: 'rgba(0, 0, 0, 0.5)',
    radioGroupBorderColor: 'rgba(0, 0, 0, 0.1)',
    alertPrimaryBg: 'rgba(217, 232, 252, 1)',
    semitransparentSecondaryText: 'rgba(0, 0, 0, 0.35)',
    inputBg: '#ffffff',
    reviewCardBg: 'rgba(245, 245, 245, 1)',
    teamMemberCardBg: 'rgba(245, 245, 245, 1)',
    panelBorderColor: 'rgba(0, 0, 0, 0.1)',
    panelBg: '#ffffff',
    trustpilotCardBg: '#F5F5F5',
    capabilityBg: '#F0F0F0',
    stateCardBg: '#ffffff',
    switchButtons: {
      activeBg: '#fff',
      defaultBg: '#F5F5F5',
      border: '#F5F5F5'
    },
    planDetails: {
      bg: '#FAFAFA',
      boder: '#ffffff',
    },
  },
  dark: {
    name: 'dark',
    primary: '#2253F5',
    primaryText: '#FFFFFF',
    secondaryText: 'rgba(255,255,255,0.65)',
    borderColor: 'rgba(255,255,255,0.1)',
    searchInputBorderColor: 'rgba(128, 141, 158, 0.25)',
    bg: 'radial-gradient(84.44% 84.68% at -3.96% -9.68%, #040D30 0%, #000006 100%)',
    htmlBg: '#000006',
    benefitsBg: 'rgba(247, 247, 247, 0.1)',
    cardsBg: '#17171F',
    navbarBg: 'rgba(15,16,28,0.85)',
    tocBorderColor: 'rgba(128, 141, 158, 0.1)',
    cardCategoryStripBg: 'rgba(255, 255, 255, 0.15)',
    cardBaseBg: 'rgba(255, 255, 255, 0.05)',
    downloadAppBg: 'rgba(233, 236, 242, 0.15)',
    notificationsBg: 'linear-gradient(180deg, rgba(255, 255, 255, 0.05) 43.57%, rgba(255, 255, 255, 0) 100%)',
    secondaryBtnBg: 'rgba(255,255,255,0.1)',
    secondaryBtnBorder: 'transparent',
    btnSecondaryActiveBg: 'rgba(237, 240, 250, 0.05)',
    cardsBorder: 'transparent',
    translucentCardsBg: 'rgba(255, 255, 255, 0.07)',
    panelSectionBg: 'linear-gradient(180deg, rgba(255, 255, 255, 0.07) 84.66%, rgba(255, 255, 255, 0) 100%)',
    howitworksSecondaryBg: 'rgba(255, 255, 255, 0.05)',
    howitworksSmsBg: 'rgba(255, 255, 255, 0.2)',
    svgDisabledColor: 'rgba(255, 255, 255, 0.1)',
    tableBottomBlur: 'linear-gradient(180deg, transparent 0%, #000006 100%)',
    tableItemHoverBg: 'rgba(255, 255, 255, 0.1)',
    specialDealsBg:
      'linear-gradient(#000006 0%, #000006 200px, rgba(255, 255, 255, 0.05) 200px, rgba(255, 255, 255, 0.05) 100%)',
    howToGetNumberBg: 'rgba(255, 255, 255, 0.05)',
    checkboxBorderColor: 'rgba(237, 240, 250, 0.2)',
    radioGroupBorderColor: 'rgba(237, 240, 250, 0.15)',
    alertPrimaryBg: 'rgba(217, 232, 252, 0.3)',
    semitransparentSecondaryText: 'rgba(255, 255, 255, 0.35)',
    inputBg: 'rgba(255, 255, 255, 0.05)',
    reviewCardBg: 'rgba(245, 245, 245, 0.1)',
    teamMemberCardBg: 'rgba(245, 245, 245, 0.1)',
    panelBorderColor: 'rgba(237, 240, 250, 0.15)',
    panelBg: 'rgba(255, 255, 255, 0.05)',
    trustpilotCardBg: 'rgba(247, 247, 247, 0.1)',
    capabilityBg: '#000',
    stateCardBg: '#000',
    switchButtons: {
      activeBg: 'rgba(255, 255, 255, 0.10)',
      defaultBg: 'transparent',
      border: 'rgba(255, 255, 255, 0.10)',
    },
    planDetails: {
      bg: 'rgba(255, 255, 255, 0.05)',
      boder: 'rgba(255, 255, 255, 0.1)',
    },
  },
};

export default themes;
