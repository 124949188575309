'use client';

import React from 'react';
import { useLocale, useTranslations } from 'next-intl';
import Link from 'next/link';
import Icon from '@/shared/ui/Icon';
import { FacebookIcon, TelegramIcon, LnIcon, WhatWeAcceptIcon } from '@/shared/ui/Icon/svg';
import { TG_DEFAULT_LINK, TG_RU_LINK } from '@/utils/constants';
import { FullSizeContainer } from '@/shared/ui/styled';
import Logo from '@/entities/Logo';
import LanguageMenu from '@/features/LanguageMenu';
import { getEsimplusLink } from '@/shared/lib';
import { BelkaOneIcon, BelkaTwoIcon, MirOneIcon, MirTwoIcon } from './assets/svg';
import {
  ListItem,
  List,
  ListTitle,
  Wrapper,
  ListWrapper,
  SocialNetworksWrapper,
  LinksWrapper,
  TopSection,
  BottomSection,
  SocialNetworksButton,
  LanguageMenuWrapper,
} from './styled';

function Footer() {
  const t = useTranslations();
  const locale = useLocale();

  return (
    <Wrapper>
      <TopSection>
        <FullSizeContainer>
          <div>
            <Logo alt="eSIM Plus logo footer" />
            <SocialNetworksWrapper>
              <SocialNetworksButton type="button">
                <Link target="_blank" rel="nofollow" href="https://www.facebook.com/esimplus.me/">
                  <Icon component={FacebookIcon} />
                </Link>
              </SocialNetworksButton>
              <SocialNetworksButton type="button">
                <Link target="_blank" rel="nofollow" href="https://www.linkedin.com/company/esimplus">
                  <Icon component={LnIcon} />
                </Link>
              </SocialNetworksButton>
              <SocialNetworksButton type="button">
                <Link target="_blank" rel="nofollow" href={locale === 'ru' ? TG_RU_LINK : TG_DEFAULT_LINK}>
                  <Icon component={TelegramIcon} />
                </Link>
              </SocialNetworksButton>
            </SocialNetworksWrapper>
            <LanguageMenuWrapper>
              <LanguageMenu adaptive={false} centeredList listPosition="top" />
            </LanguageMenuWrapper>
          </div>
          <div>
            <LinksWrapper>
              <div>
                <ListWrapper>
                  <ListTitle>{t('services')}</ListTitle>
                  <List>
                    <ListItem>
                      <Link href={getEsimplusLink(locale, '/virtual-phone-number')}>{t('virtual_numbers')}</Link>
                    </ListItem>
                    <ListItem>
                      <Link href={getEsimplusLink(locale, '/virtual-phone-number/pricing')}>
                        {t('phone_number_rates')}
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href={getEsimplusLink(locale, '/random-phone-number-generator')}>
                        {t('phone_number_generator')}
                      </Link>
                    </ListItem>
                  </List>
                </ListWrapper>
              </div>
              <div>
                <ListWrapper>
                  <ListTitle>{t('support')}</ListTitle>
                  <List>
                    <ListItem>
                      <Link href={getEsimplusLink(locale, '/faq')}>{t('faq.title')}</Link>
                    </ListItem>
                    <ListItem>
                      <Link href={getEsimplusLink(locale, '/esim-supported-devices')}>
                        {t('esim_supported_devices')}
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href={getEsimplusLink(locale, '/contact-us')}>{t('contact_us.title')}</Link>
                    </ListItem>{' '}
                    <ListItem
                      onClick={() => {
                        // eslint-disable-next-line new-cap
                        window.HelpCrunch('openChat');
                      }}
                    >
                      {t('online_support')}
                    </ListItem>
                  </List>
                </ListWrapper>
              </div>
              <div>
                <ListWrapper>
                  <ListTitle>{t('about_us.title')}</ListTitle>
                  <List>
                    <ListItem>
                      <Link href={getEsimplusLink(locale, '/reviews')}>{t('reviews.title')}</Link>
                    </ListItem>{' '}
                    <ListItem>
                      <Link href={getEsimplusLink(locale, '/about-us')}>{t('about_us.title')}</Link>
                    </ListItem>{' '}
                    <ListItem>
                      <Link href={getEsimplusLink(locale, '/news')}>{t('news.title')}</Link>
                    </ListItem>
                  </List>
                </ListWrapper>
              </div>
            </LinksWrapper>
          </div>
        </FullSizeContainer>
      </TopSection>
      <BottomSection>
        <FullSizeContainer>
          <div>Appvillis UAB, {new Date().getFullYear()}</div>
          <ul>
            <li>
              <Link href={getEsimplusLink(locale, '/privacy')}>{t('privacy_policy')}</Link>
            </li>
            <li>
              <Link href={getEsimplusLink(locale, '/terms')}>{t('terms_of_use')}</Link>
            </li>
            <li>
              <Link href={getEsimplusLink(locale, '/refund-policy')}>{t('refund_policy')}</Link>
            </li>
          </ul>
          <div>
            <Icon component={WhatWeAcceptIcon} width={130} height={24} />
            {locale === 'ru' && (
              <>
                <Icon width={48} height={24} component={BelkaOneIcon} />
                <Icon width={72} height={24} component={BelkaTwoIcon} />
                <Icon width={72} height={16} component={MirOneIcon} />
                <Icon width={70} height={24} component={MirTwoIcon} />
              </>
            )}
          </div>
        </FullSizeContainer>
      </BottomSection>
    </Wrapper>
  );
}

export { Footer };
