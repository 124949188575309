import React from 'react';

import Image from 'next/image';
import { useLocale } from 'next-intl';
import { useSearchParams } from 'next/navigation';
import { usePathname } from 'next-intl/client';

import { useOutsideClick } from '@/shared/hooks';
import { LANGUAGE_OPTIONS } from '@/utils/constants';
import LocaleLink from '@/entities/LocaleLink';
import {
  ActiveLanguage,
  ActiveLanguageFlagWrapper,
  ActiveLanguageLabel,
  LanguageMenuItem,
  LanguageMenuList,
  Wrapper,
} from './styled';

type Props = {
  adaptive?: boolean;
  centeredList?: boolean;
  listPosition?: 'top' | 'bottom';
};

function LanguageMenu({ adaptive = true, centeredList, listPosition = 'bottom' }: Props) {
  const locale = useLocale();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const queryString = searchParams.toString().length > 0 ? `?${searchParams.toString()}` : '';
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = React.useState(false);
  const [activeLanguageOption, setActiveLanguageOption] = React.useState(
    LANGUAGE_OPTIONS.find((el) => locale === el.value) ?? LANGUAGE_OPTIONS[0]
  );
  const containerRef = React.useRef<HTMLUListElement>(null);

  const handleOutsideClick = React.useCallback(() => {
    setIsLanguageMenuOpen(false);
  }, []);

  useOutsideClick(containerRef, handleOutsideClick);

  return (
    <Wrapper>
      <ActiveLanguage $adaptive={adaptive} onClick={() => setIsLanguageMenuOpen(true)}>
        <ActiveLanguageFlagWrapper $adaptive={adaptive}>
          <Image loading="eager" width={32} height={32} src={activeLanguageOption.img} alt="active language option" />
        </ActiveLanguageFlagWrapper>
        <ActiveLanguageLabel $adaptive={adaptive}>{activeLanguageOption.label}</ActiveLanguageLabel>
      </ActiveLanguage>
      <LanguageMenuList
        ref={containerRef}
        $show={isLanguageMenuOpen}
        $centeredList={centeredList}
        $position={listPosition}
      >
        {LANGUAGE_OPTIONS.map((lang) => (
          <LanguageMenuItem key={lang.value} $active={activeLanguageOption.value === lang.value}>
            <LocaleLink
              locale={lang.value}
              href={`${pathname}${queryString}`}
              onClick={(e) => {
                if (lang.value === activeLanguageOption.value) {
                  e.preventDefault();
                } else {
                  setActiveLanguageOption(lang);
                  document.documentElement.lang = lang.value;
                }

                setIsLanguageMenuOpen(false);
              }}
            >
              <div>
                <Image loading="eager" width={30} height={30} src={lang.img} alt={lang.label} />
              </div>
              <div>
                <div>{lang.label}</div>
                <div>{lang.country}</div>
              </div>
            </LocaleLink>
          </LanguageMenuItem>
        ))}
      </LanguageMenuList>
    </Wrapper>
  );
}

export { LanguageMenu };
