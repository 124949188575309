import React from 'react';
import Image from 'next/image';
import { useTheme } from '@/context/ThemeContext';
import logoWhite from './assets/logo-white-min.webp';
import logoBlack from './assets/logo-black-min.webp';
import { Wrapper } from './styled';

type LogoProps = {
  variant?: 'white' | 'black';
  alt?: string;
};

function Logo({ variant, alt = 'eSIM Plus logo' }: LogoProps) {
  const { isDarkTheme } = useTheme();

  const logoImage = React.useMemo(() => {
    switch (true) {
      case variant === 'white':
        return logoWhite;
      case variant === 'black':
        return logoBlack;
      case isDarkTheme:
        return logoWhite;
      default:
        return logoBlack;
    }
  }, [isDarkTheme, variant]);

  return (
    <Wrapper href="/">
      <Image loading="eager" width={160} height={45} src={logoImage} alt={alt} />
    </Wrapper>
  );
}

export { Logo };
