'use client';

import React, { useState } from 'react';
import { useLocale, useTranslations } from 'next-intl';
import { usePathname } from 'next-intl/client';

import Image from 'next/image';
import Icon from '@/shared/ui/Icon';
import { XMarkIcon } from '@/shared/ui/Icon/svg';
import { useModalControls } from 'shared/hooks';
import ThemeSwitcher from '@/features/ThemeSwitcher';
import Logo from '@/entities/Logo';
import Button from 'shared/ui/Button';
import LanguageMenu from '@/features/LanguageMenu';
import { sendSafeGtagEvent, sendSafeMixpanelEvent } from '@/utils/common';
import { ONE_LINK, PURCHASED_PRODUCT_TYPE_QUERY_KEY, ProductType } from '@/shared/constants';
import { getEsimplusLink } from '@/shared/lib';
import burgerMenu from './assets/burger-menu.png';
import ArrowRight from './assets/ArrowRight';
import {
  Wrapper,
  Container,
  LinksWrapper,
  Link,
  ButtonsWrapper,
  BurgerMenu,
  NavMenu,
  SelectedRegionTitle,
  SelectedRegion,
  NavMenuItem,
  SettingsWrapper,
  SideWrapper,
  BurgerMenuButton,
  DownloadAppLink,
} from './styled';

enum Regions {
  Local = 'local_esim',
  Regional = 'regional_esim',
  Global = 'global_esim',
}

function Navbar() {
  const t = useTranslations();
  const locale = useLocale();
  const pathname = usePathname();
  const [navSelectedRegion, setNavSelectedRegion] = useState<Regions | null>(null);

  const productPurchaseQueryString =
    pathname === '/payment/success' ? `&${PURCHASED_PRODUCT_TYPE_QUERY_KEY}=${ProductType.MOBILE_DATA}` : '';
  const signInLink = `/auth/login?to=/dashboard${productPurchaseQueryString}`;

  const {
    isOpen: isNavMenuOpen,
    closeModal: closeNavMenu,
    openModal: openNavMenu,
  } = useModalControls(false, { disableBodyScroll: true });

  const handleSignInClick = () => {
    sendSafeGtagEvent('signin_mobiledata_click');
    sendSafeMixpanelEvent('track', 'signin_mobiledata_click');
  };

  const handleNavMenuItemCLick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    closeNavMenu();
    e.stopPropagation();
  };

  return (
    <>
      <div style={{ paddingBottom: 65 }} />
      <Wrapper id="navbar">
        <Container style={{ color: 'inherit' }}>
          <SideWrapper $align="left">
            <Logo />
          </SideWrapper>
          <LinksWrapper>
            <Link exact href="/">
              {t('mobile_data')}
            </Link>
            <Link href={getEsimplusLink(locale, '/virtual-phone-number')}>{t('virtual_numbers')}</Link>
            <DownloadAppLink target="_blank" rel="nofollw" href={ONE_LINK}>
              {t('download_app')}
            </DownloadAppLink>
          </LinksWrapper>
          <SideWrapper $align="right">
            <SettingsWrapper>
              <ThemeSwitcher />
              <LanguageMenu />
              <ButtonsWrapper>
                <Button
                  style={{ height: 48 }}
                  onClick={handleSignInClick}
                  label={
                    <a target="_blank" rel="noreferrer" href={getEsimplusLink(locale, signInLink)}>
                      {t('sign_in')}
                    </a>
                  }
                />
              </ButtonsWrapper>
              <BurgerMenu>
                <BurgerMenuButton onClick={openNavMenu}>
                  <Image loading="eager" width={20} height={20} src={burgerMenu} alt="burger menu" />
                </BurgerMenuButton>
                <NavMenu $isOpen={isNavMenuOpen}>
                  <div>
                    <Logo />
                    <button type="button" onClick={closeNavMenu}>
                      <Icon width={14} height={14} component={XMarkIcon} />
                    </button>
                  </div>
                  {navSelectedRegion ? (
                    <SelectedRegion>
                      <SelectedRegionTitle>
                        <button type="button" onClick={() => setNavSelectedRegion(null)} aria-label="unset region">
                          <ArrowRight />
                        </button>
                        {t(navSelectedRegion as string)}
                      </SelectedRegionTitle>
                    </SelectedRegion>
                  ) : (
                    <ul>
                      <NavMenuItem>
                        <Link exact onClick={handleNavMenuItemCLick} href={getEsimplusLink(locale, '/')}>
                          {t('mobile_data')}
                        </Link>
                      </NavMenuItem>
                      <NavMenuItem>
                        <Link href={getEsimplusLink(locale, '/virtual-phone-number')} onClick={handleNavMenuItemCLick}>
                          {t('virtual_numbers')}
                        </Link>
                      </NavMenuItem>
                      <NavMenuItem>
                        <Link target="_blank" rel="nofollow" href={ONE_LINK}>
                          {t('download_app')}
                        </Link>
                      </NavMenuItem>
                    </ul>
                  )}
                  <Button
                    style={{ height: 45 }}
                    label={
                      <a target="_blank" rel="noreferrer" href={getEsimplusLink(locale, signInLink)}>
                        {t('sign_in')}
                      </a>
                    }
                  />
                </NavMenu>
              </BurgerMenu>
            </SettingsWrapper>
          </SideWrapper>
        </Container>
      </Wrapper>
    </>
  );
}

export { Navbar };
