import styled, { css } from 'styled-components';

export const ActiveLanguage = styled.div<{ $adaptive: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  padding: 12px 16px;
  border-radius: 10px;
  background: ${(props) => props.theme.benefitsBg};
  color: ${(props) => props.theme.primaryText};

  ${(props) => {
    if (props.$adaptive) {
      return css`
        @media (max-width: 768px) {
          border: 1px solid ${props.theme.borderColor};
          padding: 10px;
          background: transparent;
        }
      `
    }
  }}
`;

export const ActiveLanguageLabel = styled.div<{ $adaptive: boolean }>`
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;

  @media (max-width: 768px) {
    display: ${(props) => props.$adaptive ? 'none' : 'block'};
  }
`;

export const ActiveLanguageFlagWrapper = styled.div<{ $adaptive: boolean }>`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 100vmax;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);

  img {
    width: 26px;
    height: 26px;
  }

  ${(props) => {
    if (props.$adaptive) {
      return css`
        @media (max-width: 768px) {
          width: 20px;
          height: 20px;

          img {
            width: 22px;
            height: 22px;
          }
        }
      `
    }
  }}
`;

export const LanguageMenuList = styled.ul<{ $show: boolean, $centeredList?: boolean, $position: 'top' | 'bottom' }>`
  z-index: 4;
  visibility: ${(props) => (props.$show ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.$show ? '1' : '0')};
  max-width: 350px;
  width: max-content;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 4px;
  position: absolute;
  top: ${(props) => props.$position === 'bottom' ? '100%' : 'auto'};
  bottom: ${(props) => props.$position === 'top' ? '100%' : 'auto'};
  right: ${(props) => props.$centeredList ? 0 : '50%'};
  transform: translateX(50%);
  padding: 16px;
  background: ${(props) => props.theme.cardsBg};
  backdrop-filter: blur(125px);
  box-shadow: 0 0 20px rgba(15, 15, 15, 0.2);
  border-radius: 15px;

  li {
    overflow: hidden;
    width: 150px;
    flex: 1 1 150px;
    margin: 0;
    padding: 0;
    list-style: none;
    &::before {
      display: none;
    }
  }

  @media (max-width: 768px) {
    right: ${(props) => props.$centeredList ? '50%' : '-50px'};
  }

  @media (max-height: 500px) {
    max-width: unset;
  }

  ${(props) => {
    if (!props.$centeredList) {
      return css`
        @media (max-width: 768px) {
          transform: none;
        }
      `
    }
  }}
`;

export const LanguageMenuItem = styled.li<{ $active?: boolean }>`
  width: 100%;

  a {
    cursor: pointer;
    width: 100%;
    height: auto;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    border: none;
    background: ${(props) => (props.$active ? props.theme.primary : props.theme.benefitsBg)};

    > div {
      text-align: left;
      font-weight: 400;
      color: ${(props) => (props.$active ? 'white' : props.theme.primaryText)};

      &:first-child {
        flex: 0 0 30px;
        width: 30px;
        font-size: 0;
        border-radius: 4px;
        overflow: hidden;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;

        > img {
          width: 32px;
          height: 32px;
        }
      }

      > div:first-child {
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.01em;
      }

      > div:last-child {
        font-size: 12px;
        line-height: 14px;
        opacity: 0.4;
        white-space: nowrap;
      }
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
`;
