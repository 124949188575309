import LocaleLink from '@/entities/LocaleLink';
import styled, { css } from 'styled-components';

export const Wrapper = styled(LocaleLink) <{ $isActive?: boolean }>`
  position: relative;
  text-decoration: none;
  padding: 0;
  color: ${(props) => props.theme.primaryText};
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${(props) =>
    props.$isActive &&
    css`
      color: ${props.theme.primary};
      > svg {
        color: ${props.theme.primary} !important;
      }

      &::before {
        content: '';
        position: absolute;
        left: -10px;
        top: 50%;
        transform: translateY(-50%);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: ${props.theme.primary};
      }
    `}
`;
