'use client';

import React, { useMemo } from 'react';
import { Toaster } from 'react-hot-toast';
import { createGlobalStyle, ThemeProvider as StyledThemeProvider } from 'styled-components';
import { getCookie, setCookie } from 'utils/common';
import themes from 'shared/ui/themes';

type ThemeContextProps = {
  isDarkTheme: boolean;
  toggleTheme: () => void;
};

const ThemeContext = React.createContext<ThemeContextProps>({
  isDarkTheme: false,
  toggleTheme: () => { },
});

const GlobalStyle = createGlobalStyle`
  html {
    background: ${(props) => (props.theme as any).htmlBg} fixed;
  }
  body {
    background: ${(props) => (props.theme as any).bg};
  }
  a.active{
    color: ${(props) => (props.theme as any).primary}
  }
`;

function ThemeProvider<T extends { children: React.ReactNode }>({ children }: T) {
  const [isDarkTheme, setIsDarkTheme] = React.useState(false);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsDarkTheme(
        getCookie('isDarkTheme')
          ? !!Number(getCookie('isDarkTheme'))
          : window.matchMedia('(prefers-color-scheme: dark)').matches
      );
    }
  }, []);

  React.useEffect(() => {
    setCookie('isDarkTheme', String(Number(isDarkTheme)), 30);
  }, [isDarkTheme]);

  const value = useMemo(
    () => ({
      isDarkTheme,
      toggleTheme: () => {
        setIsDarkTheme((prev) => !prev);
        setCookie('isDarkTheme', String(Number(!isDarkTheme)), 30);
      },
    }),
    [isDarkTheme]
  );

  return (
    <ThemeContext.Provider value={value}>
      <GlobalStyle theme={themes[isDarkTheme ? 'dark' : 'light']} />
      <Toaster
        toastOptions={{
          success: {
            iconTheme: {
              primary: '#2253F5',
              secondary: '#EDF0FA',
            },
            style: {
              background: isDarkTheme ? "#1a1c2c" : "white",
              color: themes[isDarkTheme ? "dark" : "light"].primaryText,
            },
          },
          error: {
            style: {
              background: isDarkTheme ? '#1a1c2c' : 'white',
              color: themes[isDarkTheme ? 'dark' : 'light'].primaryText,
            },
          },
        }}
      />
      <StyledThemeProvider theme={themes[isDarkTheme ? 'dark' : 'light']}>{children}</StyledThemeProvider>
    </ThemeContext.Provider>
  );
}

function useTheme() {
  return React.useContext(ThemeContext);
}

export { ThemeProvider, useTheme, ThemeContext };
