import { AnchorHTMLAttributes, PropsWithChildren } from 'react';
import { useLocale } from 'next-intl';
import Link, { LinkProps } from 'next/link';
import { isLocaleSupported } from '../lib/utils';

function LocaleLink({
  locale,
  href,
  ...props
}: PropsWithChildren<LinkProps & AnchorHTMLAttributes<HTMLAnchorElement>>) {
  const intlLocale = useLocale();

  const getLocalePrefix = () => {
    let newLocale = '';

    // Check if intlLocale is supported, otherwise use a default (e.g., 'en')
    const currentLocale = isLocaleSupported(intlLocale) ? intlLocale : 'en';
    if (currentLocale === 'en') {
      if (locale && locale !== currentLocale && isLocaleSupported(locale)) {
        newLocale = `/${locale}`;
      }
    } else {
      newLocale = locale && (locale === 'en' || !isLocaleSupported(locale)) ? '' : `/${locale || currentLocale}`;
    }

    return newLocale;
  };

  return <Link href={href.includes('http') ? href : `${getLocalePrefix()}${href}`} {...props} />;
}

export default LocaleLink;
