'use client';

import React from 'react';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import Icon from './Icon';
import { OrnamentIcon } from './Icon/svg';

export const PanelSectionTitle = styled.div`
  padding: 15px 25px;
  color: ${(props) => props.theme.primaryText};
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;

  > div {
    display: flex;
    align-items: center;
    grid-gap: 10px;
  }

  @media (max-width: 430px) {
    padding: 16px;
  }
`;
export const PanelSection = styled.div<{ flex?: string | number }>`
  background: ${(props) => props.theme.panelSectionBg};
  border-radius: 5px;
  flex: ${(props) => props.flex};
  overflow: hidden;
  margin: 5px auto;
  max-width: 900px;
  display: flex;
  flex-direction: column;

  &:only-of-type {
    border-radius: 25px !important;
  }
`;

PanelSection.defaultProps = {
  flex: 1,
};

export const LinkButton = styled.button`
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  color: ${(props) => props.theme.primary};
  font-weight: 300;
  font-size: inherit;
  cursor: pointer;
`;

export const PanelSectionsWrapper = styled.div<{
  dir?: 'column' | 'row';
  $maxWidth?: number;
}>`
  display: flex;
  grid-gap: 5px;
  max-width: ${(props) => props.$maxWidth}px;
  margin: 50px auto;

  ${PanelSection} {
    margin: 0;
  }

  ${(props) => {
    switch (props.dir) {
      case 'column':
        return css`
          flex-direction: column;
          ${PanelSection} {
            flex: 1;

            &:first-of-type:not(:only-of-type) {
              border-radius: 25px 25px 5px 5px;
            }
            &:last-of-type:not(:only-of-type) {
              border-radius: 5px 5px 25px 25px;
            }
          }
        `;
      case 'row':
        return css`
          flex-direction: row;

          ${PanelSection} {
            &:first-of-type:not(:only-of-type) {
              border-radius: 25px 5px 5px 25px;
            }
            &:last-of-type:not(:only-of-type) {
              border-radius: 5px 25px 25px 5px;
            }
          }
        `;
      default:
        return css``;
    }
  }}
`;

PanelSectionsWrapper.defaultProps = {
  $maxWidth: 900,
  dir: 'row',
};

export const NoMatchesText = styled.div`
  padding: 0 25px;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: ${(props) => props.theme.secondaryText};
`;

export const Card = styled.div`
  background: ${(props) => props.theme.translucentCardsBg};
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border: 1px solid ${(props) => props.theme.cardsBorder};
  border-radius: 20px;
`;

export const Paragraph = styled(motion.p)`
  text-align: left;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  color: ${(props) => props.theme.secondaryText};
  white-space: pre-line;

  a {
    text-decoration: underline;
    color: ${(props) => props.theme.primary};
  }
`;

export const ContentBlock = styled.div`
  margin: 100px 0;

  > ${Paragraph} {
    margin: 50px 0 0 0;

    > ul {
      padding-left: 40px;
      margin: 25px 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-size: 0.9rem;

      li {
        list-style: circle;
      }
    }
  }

  @media (max-width: 768px) {
    margin: 70px 0;

    > ${Paragraph} {
      margin: 30px 0 0 0;
      > ul {
        padding-left: 16px;
        margin: 20px 0;
      }
    }
  }
`;

export const Container = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 50px;
  max-width: 1240px;
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.primaryText};

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const FullSizeContainer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 50px;
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.primaryText};

  @media (max-width: 1280px) {
    padding: 0 20px;
  }

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const OrderedListItem = styled.li`
  position: relative;
  padding-left: 40px;
  counter-increment: counter;
  font-size: 16px;
  line-height: 26px;
  color: ${(props) => props.theme.secondaryText};

  &:before {
    content: counter(counter);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 100vmax;
    color: white;
    background: ${(props) => props.theme.primary};
    font-size: 12px;
    line-height: 26px;
  }
`;
export const OrderedList = styled.ol`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const SectionTitleIcon = styled(Icon)`
  position: absolute;
  width: 90px;
  height: 100px;
  background-size: contain;
  left: 0;
  top: 0;
  transform: translate(-45%, -35%);
`;

export const PageTitle = styled.h1`
  position: relative;
  white-space: pre-line;
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  color: ${(props) => props.theme.primaryText};
  text-align: left;
  max-width: 490px;

  @media (max-width: 768px) {
    margin-bottom: 25px;
    font-size: 40px;
    line-height: 48px;
  }
`;

export const SectionTitle = styled(({ children, ...props }: JSX.IntrinsicElements['h2']) => (
  <h2 {...props}>
    <SectionTitleIcon component={OrnamentIcon} />
    {children}
  </h2>
))`
  position: relative;
  white-space: pre-line;
  margin: 1rem 0;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  color: ${(props) => props.theme.primaryText};
  text-align: left;

  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 33px;
  }
`;

export const SubSectionTitle = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.2px;
  margin-bottom: 15px;
  color: ${(props) => props.theme.primaryText};
`;

export const SubSection = styled.div`
  padding: 25px 0;
  flex: 1;
`;

export const SubSectionsWrapper = styled.div<{ dir?: 'row' | 'column' }>`
  display: flex;
  flex: 1;

  ${(props) => {
    switch (props.dir) {
      case 'row':
        return css`
          flex-direction: row;

          ${SubSection}:not(:only-child):not(:last-of-type) {
            border-right: 1px solid ${props.theme.borderColor};
          }
          ${SubSection}:first-of-type:not(:only-child) {
            padding-right: 25px;
          }
          ${SubSection}:last-of-type:not(:only-child) {
            padding-left: 25px;
          }
        `;
      case 'column':
        return css`
          flex-direction: column;

          ${SubSection} {
            padding: 25px 0;
          }
          ${SubSection}:not(:only-child):not(:last-of-type) {
            border-bottom: 1px solid ${props.theme.borderColor};
          }
        `;
      default:
        return css``;
    }
  }}
`;

SubSectionsWrapper.defaultProps = { dir: 'row' };

export const SectionContent = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const Section = styled(Container)`
  margin: 50px auto;
  display: flex;
  gap: 50px;
  justify-content: space-between;
  flex: 1;

  ${SectionTitle} {
    margin: 0;

    ${SectionTitleIcon} {
      display: none;
    }
  }

  @media (max-width: 768px) {
    gap: 20px;
    flex-direction: column;

    &:has(> ${SectionContent}) {
      gap: 0;
    }

    ${SectionTitle} {
      font-size: 40px;
      line-height: 48px;
    }
  }
`;

type GridWrapperBaseProps = { gap?: number; $minWidth?: number | string };

export const GridWrapper = styled.div<GridWrapperBaseProps & { md?: GridWrapperBaseProps; sm?: GridWrapperBaseProps }>`
  display: grid;
  gap: ${(props) => props.gap}px;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${(props) => `${props.$minWidth}${Number(props.$minWidth) ? 'px' : ''}`}, 1fr)
  );

  @media (max-width: 450px) {
    gap: ${(props) => props.sm?.gap}px;
    grid-template-columns: repeat(
      auto-fill,
      minmax(${(props) => `${props.sm?.$minWidth}${Number(props.sm?.$minWidth) ? 'px' : ''}`}, 1fr)
    );
  }
`;

GridWrapper.defaultProps = { gap: 10, $minWidth: 0, sm: { $minWidth: 300, gap: 10 } };
