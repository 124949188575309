import React from 'react';
import { useTheme } from '@/context/ThemeContext';
import Icon from '@/shared/ui/Icon';
import { MoonIcon, SunIcon } from '@/shared/ui/Icon/svg';
import { Wrapper, Label } from './styled';

type ThemeSwitcherProps = {
  disableTransition?: boolean;
  size?: 'medium' | 'small';
};

function ThemeSwitcher({ disableTransition = false, size = 'medium' }: ThemeSwitcherProps) {
  const { isDarkTheme, toggleTheme } = useTheme();
  const [checked, setChecked] = React.useState(isDarkTheme);

  React.useEffect(() => {
    setChecked(isDarkTheme);
  }, [isDarkTheme]);

  return (
    <Wrapper size={size}>
      <Label
        $disabletransition={disableTransition}
        checked={checked}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          toggleTheme();
        }}
      >
        <input type="checkbox" onChange={toggleTheme} checked={checked} />
        <div>
          <div>
            <Icon component={SunIcon} />
            <Icon component={MoonIcon} />
          </div>
        </div>
      </Label>
    </Wrapper>
  );
}

export { ThemeSwitcher };
